import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import API from "../../Api/Api";
import Traceability from "../Traceability";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import RecallDisplayCard from './RecallDisplayCard';
import RecallForm from './RecallForm';
import CircularProgress from "@material-ui/core/CircularProgress";
import TraceabilityUtils from "../TraceabilityUtils";
import { useTheme } from "@material-ui/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { StylesContext } from "../../App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.055)',
      },
    },
  },
})(TextField);

//todo make the card buttons fully functional
export default function RecallManager(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);
  const theme = useTheme();

  const [recallRecords, setRecallRecords] = useState(false);
  const [recallSelected, setRecallSelected] = useState(false);
  const [editingMode, setEditingMode] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [recallFormOpen, setRecallFormOpen] = useState(false);
  // const [skus, setSkus] = useState(false);
  const [units, setUnits] = useState(false);
  const [user, setUser] = useState(null);
  const [lotCodes, setLotCodes] = useState(false);
  // const [internalLotCodes, setInternalLotCodes] = useState(false);
  // const [externalLotCodes, setExternalLotCodes] = useState(false);
  // const [combinedLotCodes, setCombinedLotCodes] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const api = new Traceability().getFRecallAPI();
    const authApi = new API().getAuthAPI();
    // const skuApi = new Traceability().getSkuApi();
    const unitApi = new Traceability().getUnitsAPI();
    // const internalLotCodeApi = new Traceability().getInternalLotCodeAPI();
    // const externalLotCodeApi = new Traceability().getExternalLotCodeAPI();

    if (props.user) {
      setUser(props.user)
    }
    else {
      authApi.getAuthedProfile().then(e => {
        setUser(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.recallRecords) {
      setRecallRecords([...props.recallRecords]);
    }
    else {
      api.listFRecall().then(e => {
        setRecallRecords(e.data);
      }).catch(e => {
        //console.log('BE Error: ' + e);
      })
    }

    // if (props.skus) {
    //   setSkus(props.skus);
    // }
    // else {
    //   utils.pkIndexObjectsFromApi(skuApi.listSkusDisplay.bind(skuApi), setSkus);
    //   /*skuApi.listSkusDisplay().then(e => {
    //     setSkus(e.data);
    //   })*/
    // }

    if (props.units) {
      setUnits(props.units)
    }
    else {
      utils.pkIndexObjectsFromApi(unitApi.listUnits.bind(unitApi), setUnits);
      /*unitApi.listUnits().then(e => {
        setUnits(e.data);
      })*/
    }

    if (props.lotCodes) {
      setLotCodes(props.lotCodes)
    }
    else {
      api.listLotCodeFRecall().then((e) => {
        setLotCodes(e.data)
      }).catch(e => {
        //console.log(e);
      })
    }

    // if (props.externalLotCodes) {
    //   setInternalLotCodes(props.externalLotCodes)
    // }
    // else {
    //   externalLotCodeApi.listExternalLotCodes().then((e) => {
    //     setExternalLotCodes(e.data)
    //   }).catch(e => {
    //     //console.log(e);
    //   })
    // }

  }, [])

  // useEffect(() => {
  //   if (internalLotCodes && externalLotCodes) {
  //     let newInternalLotCodes = internalLotCodes.map((lotCode) => {
  //       return { ...lotCode, type: 'internal' }
  //     })
  //     let newExternalLotCodes = externalLotCodes.map((lotCode) => {
  //       return { ...lotCode, type: 'external' }
  //     })
  //     setCombinedLotCodes([...newInternalLotCodes, ...newExternalLotCodes]);
  //   }
  // }, [internalLotCodes, externalLotCodes])

  function closeModal() {
    if (!shouldClose) {
      if (!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }
    cancel();
  }

  function cancel() {
    //setOrderSelected(-1);

    setRecallFormOpen(false);
    setRecallSelected(false);
  }

  function onSave(response) {
    let updatedRecallId = response.data.id;
    let updatedRecalls = [...recallRecords];

    let recallIndex = updatedRecalls.findIndex(recall => {
      return recall.id === updatedRecallId;
    });

    if (recallIndex > -1) {
      updatedRecalls.splice(recallIndex, 1, response.data);
    }
    else {
      updatedRecalls.push(response.data);
    }

    setRecallRecords(updatedRecalls);
  }

  function onDelete(recallId) {
    setRecallRecords(prevRecords =>
      prevRecords.filter(recall => recall.id !== recallId)
    );
  }

  function makeRecallCards() {
    if (searchValue && searchValue != "") {
      let recallRecordsToShow = recallRecords.filter(recall => recall.recall_name && recall.recall_name.includes(searchValue));
      if (recallRecordsToShow.length > 0) {
        // return recallRecordsToShow.map((recallRecord, i) => {
        //   return (
        //     <Grid key={i} item xs={12} style={{ margin: '0px 32px 16px', padding: '0px' }}>
        //       <RecallDisplayCard
        //         onDelete={onDelete}
        //         recall={recallRecord}
        //         user={user}
        //         setRecallSelected={setRecallSelected}
        //         setRecallFormOpen={setRecallFormOpen}
        //       />
        //     </Grid>
        //   )
        // })
        return (
          <Grid item xs={12} container justify="flex-start" spacing={2}>
          <Grid item xs={12} style={{ margin: '0px 32px 16px', padding: '0px' }}>
            {recallRecordsToShow.map((recallRecord) => (
              <RecallDisplayCard
                onDelete={onDelete}
                recall={recallRecord}
                user={user}
                setRecallSelected={setRecallSelected}
                setRecallFormOpen={setRecallFormOpen}
              />
            ))}
          </Grid></Grid>)     
      }
      else {
        return (
          <Grid item xs={12} style={{ margin: 'auto', width: 'calc(100% - 64px)' }}>
            <Paper elevation={0}>
              <Grid item container spacing={3} justify="center" alignItems="center" style={{ height: '64px' }}>
                <Grid item container justify="center" alignItems="center" xs={12}>
                  <Typography style={{
                    color: '#9798B0',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    lineHeight: '21px',
                    letterSpacing: '0.1px',
                  }}
                  >
                    No recalls match the search criteria.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )
      }
    }
    else {
      if (recallRecords.length > 0) {
        // return recallRecords.map((recallRecord, i) => {
        //   return (
        //     <Grid key={i} item xs={12} style={{ margin: '0px 32px 16px', padding: '0px' }}>
        //       <RecallDisplayCard
        //         onDelete={onDelete}
        //         recall={recallRecord}
        //         setRecallSelected={setRecallSelected}
        //         setRecallFormOpen={setRecallFormOpen}
        //       />
        //     </Grid>
        //   )
        // })
        return (
          <Grid item xs={12} container justify="flex-start" spacing={2}>
          <Grid item xs={12} style={{ margin: '0px 32px 16px', padding: '0px' }}>
            {recallRecords.map((recallRecord) => (
              <RecallDisplayCard
                onDelete={onDelete}
                recall={recallRecord}
                user={user}
                setRecallSelected={setRecallSelected}
                setRecallFormOpen={setRecallFormOpen}
              />
            ))}
          </Grid></Grid>)
      }
      else {
        return (
          <Grid item xs={12} style={{ margin: 'auto', width: 'calc(100% - 64px)' }}>
            <Paper elevation={0}>
              <Grid item container spacing={3} justify="center" alignItems="center" style={{ height: '64px' }}>
                <Grid item container justify="center" alignItems="center" xs={12}>
                  <Typography style={{
                    color: '#9798B0',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    lineHeight: '21px',
                    letterSpacing: '0.1px',
                  }}
                  >
                    You have no recalls. Click "New Recall" to add one to your plan.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )
      }
    }

  }

  function searchChange(newValue) {
    setSearchValue(newValue);
  }

  function createNewRecall() {
    setRecallSelected({ lot_codes: [], recall_name: '' });
    //setEditingMode(true);
    setRecallFormOpen(true);
  }

  function returnHeaderComponent() {
    return (
      <Paper elevation={0} square className={classes.generalPaperFullLengthHeader} style={{ height: '96px' }}>
        <Grid container direction="row" justify="space-between" alignItems="center" style={{ margin: '0px', paddingTop: '30px' }}>
          <Grid item xs={8}
            style={{
              margin: '0px',
              padding: '0px 32px',
              color: theme.palette.tabTable.headerColor,
              fontFamily: 'Roboto',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
            }}
          >
            <Typography
              variant="h6"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Recall Manager
            </Typography>
          </Grid>
          <Grid item container justify="flex-end" xs={3} style={{ margin: '0px', paddingRight: '25px' }}>
            <CssTextField
              //autoFocus={this.props.searchAutoFocus}
              /*className={
                this.props.searchFieldAlignment === "left" &&
                this.props.showTitle === false
                  ? null
                  : this.props.classes.searchField
              }*/
              value={searchValue}
              onChange={(event) => searchChange(event.target.value)}
              placeholder={'Search Recalls'}
              variant='outlined'
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={'Search'}>
                      <Search fontSize="small" />
                    </Tooltip>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={props.disabled}
                      onClick={() => searchChange("")}
                      aria-label={'Search'}
                    >
                      <Clear
                        fontSize="small"
                        aria-label="clear"
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                //style: this.props.searchFieldStyle,
                inputProps: {
                  "aria-label": 'Search',
                },
              }}
            />
          </Grid>

        </Grid>
      </Paper>
    )
  }

  // console.log("recallRecords >> ", recallRecords);
  // console.log("internalLotCodes >> ",internalLotCodes);
  return (
    <>
      {(!recallRecords || !lotCodes) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }
      {recallRecords && lotCodes &&
        <Paper square outlined className={classes.generalListPaperContainer}>
          <Grid container spacing={0} direction="row" justify="center" alignItems="flex-start">
            <Grid item container xs={12}>
              {returnHeaderComponent()}
            </Grid>

            <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center" style={{ margin: '0px 32px 16px', padding: '0px', height: '48px' }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: '16px' }}
                onClick={() => { createNewRecall() }}
                disabled={props.disabled}
              >
                New Recall
              </Button>
            </Grid>

            <Grid item spacing={0} container xs={12}>
              <Paper elevation={0} square className={classes.generalListPaperContainer} style={{ margin: '0px' }} >
                <Grid spacing={0} container direction="column" justify="flex-start" alignItems="center">
                  {makeRecallCards()}
                </Grid>
              </Paper>
            </Grid>

            <Dialog fullScreen open={recallFormOpen && recallSelected} onClose={closeModal} TransitionComponent={Transition}>
              <DialogContent className={classes.generalFormDialogueContainer} style={{ padding: '0px' }}>
                <RecallForm
                  recall={recallSelected}
                  // combinedLotCodes={combinedLotCodes}
                  editingLotMode={editingMode}
                  // skus={skus}
                  setShouldClose={setShouldClose}
                  units={units}
                  lotCodes={lotCodes}
                  closeModal={closeModal}
                  onSave={onSave}
                  user={user}
                  activeTraceability={props.activeTraceability}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        </Paper>
      }
    </>
  )
}

RecallManager.propTypes = {
  recallRecords: PropTypes.object,
  closeModal: PropTypes.func,
  skuInstance: PropTypes.object,
  // skus: PropTypes.array,
  handleAddItem: PropTypes.func,
  user: PropTypes.object,
}
